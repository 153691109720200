<template>
  <section id="student-account">
    <v-row v-if="!loading">
      <v-col cols="12" xl="9" lg="10">
        <div class="text-h5 ml-2 mb-5">Student Accounts</div>
        <v-row no-gutters class="mb-0 pb-0">
          <v-col cols="12" lg="3">
            <v-text-field
              solo
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              v-model="$store.state[store].query"
              v-on:input="search($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              solo
              prepend-inner-icon="mdi-calendar"
              :items="$helpers.showDate()"
              v-model="$store.state[store].date"
              v-on:input="searchDate($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              solo
              prepend-inner-icon="mdi-account-multiple-outline"
              :items="$helpers.showPage()"
              item-text="text"
              item-value="value"
              v-model="$store.state[store].count"
              v-on:input="searchCount($event)"
            />
          </v-col>
          <v-col cols="12" lg="3">
            <v-btn @click="reset" small class="mt-1 ml-5" color="warning" fab>
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-btn
              @click="add"
              small
              class="mt-1 mx-2"
              color="primary"
              fab
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                @click="exportBtn"
                small
                v-bind="attrs"
                v-on="on"
                dark
                class="mt-1"
                color="danger"
                fab>
                  <v-icon size="20">mdi-export</v-icon>
                </v-btn>
              </template>
              <span>Export</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="11">
            <v-sheet color="transparent">
              <Table
                v-if="$store.getters['admin/countGetters'] > 0"
                :data="students.data"
                :loading="tloading"
                :category="category"
                @delete="remove"
                @update="update($event)"
              />
              <Table v-else :data="students" :loading="tloading" @delete="remove" />
            </v-sheet>

            <Pagination
              v-if="students.total > 0"
              :store="store"
              collection="students"
              @load="tloading=$event"
            />

            <div v-else class="text-center body-1 mt-5">
              {{ students ? "" : "No data found" }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="body-2" v-else>Loading...</div>

    <AddStudent
      :dialog="dialog"
      @close="dialog = !dialog"
      @save="accountSave"
    />

    <snackbar :snack="snack" :text="snackText" @close="snack = !snack" timeout="2000"/>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Pagination from "../../constants/paginate/Pagination.vue";
import AddStudent from "../components/account/AddStudentModal.vue";
import Table from "../components/account/TableStudent.vue";

export default {
  components: {
    Pagination,
    AddStudent,
    Table,
  },
  data: () => ({
    loading: true,
    dialog: false,
    store: "admin",
    snack: false,
    snackText: "",
    tloading: false,
  }),
  computed: {
    ...mapState("admin", {
      students: (state) => state.students,
      category: (state) => state.user_category,
    }),
  },
  mounted() {
    this.fetch();
    this.getUserCategory()
  },
  methods: {
    ...mapActions('admin',[
      'getStudentsAction', 
      'deleteStudentAction', 
      'exportStudents',
      'getUserCategory'
    ]),
    ...mapMutations('admin', ['resetPageMutation','studentsMutation']),

    fetch() {
      this.getStudentsAction({
          page: 0,
          query: "",
          date: "",
          count: 10,
        })
        .then(() => {
          this.loading = false;
        });
    },

    search(event) {
      var vm = this;
      vm.getStudentsAction({
        page: 0,
        query: event,
        date: vm.$store.state[vm.store].date,
        count: vm.$store.state[vm.store].count,
      });
    },

    searchDate(event) {
      var vm = this;
      vm.getStudentsAction({
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: event,
        count: vm.$store.state[vm.store].count,
      });
    },

    searchCount(event) {
      var vm = this;
      vm.getStudentsAction({
        page: 0,
        query: vm.$store.state[vm.store].query,
        date: vm.$store.state[vm.store].date,
        count: event,
      });
    },

    reset() {
      this.resetPageMutation();
      this.fetch();
    },

    remove(id) {
      if (confirm("Delete selected account?")) {
        this.deleteStudentAction(id);
        this.snack = true;
        this.snackText = "Account Deleted!";
      }
    },
    
    add() {
      this.getUserCategory()
      .then(() => {
        this.dialog = true

      })
    },

    accountSave() {
      this.snack = true;
      this.snackText = "Account Added!";
    },

    exportBtn() {
      this.exportStudents()
    },

    update(e) {
      this.snack = true;
      this.snackText = e;
    }
  },
  beforeDestroy() {
    this.studentsMutation([])
    this.reset();
  },
};
</script>

<style>
</style>