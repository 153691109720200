<template>
  <v-dialog persistent max-width="450" v-model="dialog">
    <v-card rounded>
      <v-card-title
        class="d-flex justify-space-between align-center primary white--text"
      >
        <div class="">New Student</div>
        <v-btn icon small @click="$emit('close')">
          <v-icon color="white" size="18">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-checkbox v-model="bulk" label="Bulk upload"></v-checkbox>
        <v-form
          v-if="!bulk"
          ref="addForm"
          @submit.prevent="save"
          class="mt-5 mx-2"
        >
          <div class="d-flex">
            <div class="mx-1">
              <label class="caption">FIRSTNAME *</label>
              <v-text-field
                solo
                v-model="form.firstname"
                :error-messages="errors.firstname"
              />
            </div>
            <div class="mx-1">
              <label class="caption">LASTNAME *</label>
              <v-text-field
                solo
                v-model="form.lastname"
                :error-messages="errors.lastname"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-1">
              <label class="caption">MIDDLENAME</label>
              <v-text-field
                solo
                v-model="form.middlename"
                :error-messages="errors.middlename"
              />
            </div>
            <div class="mx-1">
              <label class="caption">CONTACT *</label>
              <v-text-field
                type="number"
                solo
                v-model="form.contact"
                :error-messages="errors.contact"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-1">
              <label class="caption">EMAIL *</label>
              <v-text-field
                solo
                type="email"
                v-model="form.email"
                :error-messages="errors.email"
              />
            </div>
            <div class="mx-1">
              <label class="caption">USER LEVEL</label>
              <v-select solo 
              v-model="form.level"
              :items="category"
              item-text="name"
              item-value="id"/>
            </div>
          </div>
        
          <v-btn
            block
            outlined
            large
            color="primary"
            :type="!loading ? 'submit' : ''"
            class="mt-2"
            >{{ !loading ? "Submit" : "Submitting..." }}</v-btn
          >
        </v-form>
        <v-form v-else @submit.prevent="bulkForm" ref="bform">
          <label class="caption">CSV FILE *</label>
          <v-file-input solo v-model="file" :error-messages="errors.file" />
          <v-btn
            block
            large
            outlined
            color="primary"
            :type="!loading ? 'submit' : ''"
            class="mt-2"
            >{{ !loading ? "Upload" : "Uploading..." }}</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["dialog"],
  data: () => ({
    form: {
      firstname: "",
      lastname: "",
      middlename: "",
      contact: "",
      email: "",
      level: ''
    },
    bulk: false,
    file: "",
    loading: false,
  }),
  computed: {
    ...mapState("admin", {
      errors: (state) => state.errors,
      category: (state) => state.user_category,
    }),
  },
  methods: {
    ...mapActions("admin", ["addStudentAction", "bulkStudentAction"]),

    save() {
      this.loading = true;
      this.addStudentAction(this.form).then(() => {
        this.loading = false;
        if (this.$errors("admin")) {
          console.log("errors");
          return;
        }
        this.$emit("close");
        this.$refs.addForm.reset();
        this.$emit("save");
      });
    },
    bulkForm() {
      this.loading = true;
      console.log(this.file);
      var form = new FormData();
      form.append("file", this.file);
      this.bulkStudentAction(form).then(() => {
        this.loading = false;
        if (this.$errors("admin")) {
          console.log("errors");
          return;
        }
        this.$emit("close");
        this.$refs.bform.reset();
        this.bulk = false;
        this.$emit("save");
      });
    },
  },
};
</script>