<template>
  <v-row align="center" justify="center" class="my-1">
    <v-col cols="12" lg="8">
      <v-pagination
        v-model="currentPage"
        :length="lastPage"
        color="primary"
      ></v-pagination>
    </v-col>
  </v-row>
  
</template>

<script>

export default {
  props: ['store', 'collection'],
  computed: {
    currentPage: {
      get() {
        return this.$store.state[this.store][this.collection].current_page
      },
      set(val) {
        return this.$store.commit(`${this.store}/studentsPageMutation`, val)
      }
    },
    lastPage: {
      get() {
        return this.$store.state[this.store][this.collection].last_page
      }
    }
  },
  methods: {
    paginate(pageNumber) {
      this.$emit('load',true)
      
      this.$store.dispatch(`${this.store}/getStudentsAction`,{
        page: pageNumber,
        query: this.$store.state[this.store].query,
        date: this.$store.state[this.store].date,
        count: this.$store.state[this.store].count,
      })
      setTimeout(() => {
        this.$emit('load',false)
      }, 1000)
    }
  },
  watch: {
    currentPage(val) {
      this.paginate(val)
    }
  }
}
</script>