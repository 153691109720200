<template>
  <v-simple-table fixed-header loading="true" class="simple-table pt-1">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption">Image</th>
          <th class="text-left caption">Email</th>
          <th class="text-left caption">Name</th>
          <th class="text-left caption">USER LEVEL</th>
          <!-- <th class="text-left caption">Credentials</th> -->
          <th class="text-left caption">Joined</th>
          <th class="text-left caption">
            Row count
            {{
              $store.getters["admin/countGetters"] == 0
                ? data.length
                : `${$store.getters["admin/studentsGetters"].to} of ${$store.getters["admin/studentsGetters"].total}`
            }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <loading-table col="6" v-if="loading" />

        <tr v-for="item in data" :key="item.id">
          <td>
            <v-avatar v-if="!item.image" color="grey lighten-2" size="36"/>
            <v-avatar v-else>
              <v-img :src="`${item.aws}/users/${item.uuid}/image/${item.image.image}`"/>
            </v-avatar>
          </td>
          <td class="" width="10%">{{ item.email }}</td>
          <td class="" width="30%">{{ item.firstname + " " + item.lastname }}</td>
          <td class="">
            <div style="width: 200px">
              <v-select v-model="item.level" 
              solo
              class="py-2"
              :items="category"
              dense
              hide-details
              item-text="name"
              item-value="id"
              v-on:input="changeLevel($event, item)"/>
            </div>
          </td>
          <!-- <td class="">
            <v-chip small color="success" v-if="item.type == 'Email Sent'">
              {{ item.type }}
            </v-chip>
          </td> -->
          <td class="" width="15%">{{ $helpers.dateDiff(item.created_at) }}</td>
          <td width="30%">
            <v-btn
              icon
              :to="{
                name: 'Admin Student Details',
                params: { uuid: item.uuid },
              }"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn icon class="mx-1" @click="$emit('delete', item.id)">
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on" @click="resetPassword(item.id)">
                  <v-icon small>mdi-reload</v-icon>
                </v-btn>
              </template>
              <span>Reset Password</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: ["data", "loading", 'category'],
  methods: {
    ...mapActions('admin', [
      'studentLevelChange',
      'studentDefaultPassword',
    ]),

    changeLevel(e, item) {
      console.log(e+item);
      this.studentLevelChange({
        id: item.id,
        level: e
      }).then(() => {
        this.$emit('update', 'Level Change!')
      })
    },

    resetPassword(id) {
      if(confirm('Reset password to default?')) {
        this.studentDefaultPassword({ id: id })
        .then(() => {
          this.$emit('update', 'Password Reset!')
        })
      }
      
    }
  }
};
</script>